import React from 'react'
import {Heading} from '@chakra-ui/react'
import {animated, config, useSpring} from 'react-spring'
import MySEO from '../components/seo'

import {Text} from '@chakra-ui/react'


const Impressum = () => {
    const props = useSpring({
        from:{color:'white',fontSize:"1rem"},

        to: {color:'black',fontSize:"1.5rem"},
        config:config.wobbly
    })
    return(
        <animated.div style={props}>
      <MySEO
        title="Kontakt"
        description="Kontaktdaten von Hasen oder Bein"
      />

            <Heading># Impressum | <span style={{color:"grey", fontWeight:200}}>imprint</span></Heading>
        <Text>


            Katrin Gloggengießer  |  Dipl. Kommunikationsdesignerin (FH) <br/>
            Hauptwohnsitz: Langstraße 48 | 55422 Bacharach<br/>
            Zweitwohnsitz: Schleusenufer 5 | 10997 Berlin<br/>
            mobil: 0151.70842472<br/>
            mail: kontakt@hasenoderbein.de<br/>
            Datenschutzbeauftragte: Katrin Gloggengießer <br/>

        </Text>


        </animated.div>
    )}
    export default Impressum
